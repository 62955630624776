<template>
  <v-card id="card-modal-assign-driver" class="elevation-4">
    <v-card-title class="justify-start py-2 card-modaltitle">
      <div class="pl-4">
        <h3 class="primary--text pl-4">
          {{ $t("components.views.trip.offers.transport_company.modal_details.detail") }}
        </h3>
      </div>
      <v-btn depressed icon color="primary" class="cont" @click.stop="$emit('closeForm')">
        <v-icon class="mr-1">fa-solid fa-xmark</v-icon>
      </v-btn>
    </v-card-title>

    <v-card-text>
      <v-container fluid>
        <v-row >
          <v-col cols="12" >
            <v-tabs v-model="tab" fixed-tabs >
              <v-tabs-slider color="secondary"></v-tabs-slider>
              <v-tab href="#Details" active-class="primary white--text" >
                <v-icon size="14" class="mr-2">fa-solid fa-info-circle</v-icon>
                {{
                  $t(
                    "components.views.trip.offers.transport_company.modal_details.general_information"
                  )
                }}
              </v-tab>
              <v-tab href="#DetailsStop" active-class="primary white--text" >
                <v-icon size="14" class="mr-2">fa-solid fa-info-circle</v-icon>
                {{
                  $t("components.views.trip.offers.transport_company.modal_details.stops_detail")
                }}
              </v-tab>
            </v-tabs>
          </v-col>
        </v-row>
      </v-container>
      <v-tabs-items v-model="tab" >
        <v-tab-item value="Details" >
          <v-card id="details_stop" >
            <v-container fluid class="pa-4 mt-0">
              <v-row no-gutters class="mb-0 pb-0">
                <v-col md="4">
                  <strong>
                    {{
                      $t("components.views.trip.offers.transport_company.modal_details.weight")
                    }}:</strong
                  >
                </v-col>
                <v-col md="4"> {{ weight }}. Kg </v-col>
              </v-row>
              <v-row no-gutters class="mb-0 pb-0">
                <v-col md="4">
                  <strong>
                    {{
                      $t("components.views.trip.offers.transport_company.modal_details.doc_ref1")
                    }}:</strong
                  >
                </v-col>
                <v-col md="4"> {{ doc_ref1 }} </v-col>
              </v-row>
            </v-container>
          </v-card>
        </v-tab-item>
        <v-tab-item value="DetailsStop">
          <v-card id="details_stop ">
            <v-container fluid class="pa-4 mt-0">
              <v-row no-gutters class="mb-0 pb-0">
                <v-spacer />
                <v-col md="12" class="mr-2 mb-0 pb-0">
                  <v-text-field
                    prepend-icon="fa-solid fa-magnifying-glass"
                    outlined
                    hide-details
                    dense
                    v-model="searchStops"
                    clearable
                    @keydown.enter="onSearch"
                    @click:clear="cleanField()"
                    :label="
                      $t('components.views.trip.offers.transport_company.modal_details.search')
                    "
                    :placeholder="
                      $t(
                        'components.views.trip.offers.transport_company.modal_details.search_placeholder'
                      )
                    "
                  />
                </v-col>
              </v-row>
              <v-container id="content-stop" class="mt-1">
                <v-row v-if="!loaderStops && stops.length > 0" id="row-result">
                  <v-col cols="12" class="pt-0">
                    <v-timeline align-top dense>
                      <v-timeline-item color="primary" fill-dot v-for="(stop, i) in stops" :key="i">
                        <template slot="icon">
                          <v-icon color="white" v-if="i == 0">$vuetify.icons.custom.Envio </v-icon>
                          <v-icon color="white" v-if="i !== 0"
                            >$vuetify.icons.custom.Entrega
                          </v-icon>
                        </template>
                        <v-row class="pt-1">
                          <v-col cols="3">
                            <label>
                              {{
                                $t(
                                  "components.views.trip.offers.transport_company.modal_details.date"
                                )
                              }}:
                              {{ stop.schedule_date }}
                            </label>
                            <p>
                              {{
                                $t(
                                  "components.views.trip.offers.transport_company.modal_details.hour"
                                )
                              }}:
                              {{ stop.schedule_date }}
                            </p>
                          </v-col>
                          <v-col>
                            <strong>{{ stop.customer }}</strong>
                            <div class="text-caption">
                              <b
                                >{{
                                  $t(
                                    "components.views.trip.offers.transport_company.modal_details.address"
                                  )
                                }}:</b
                              >
                              {{ stop.address }}
                            </div>
                            <div class="text-caption">
                              <b
                                >{{
                                  $t(
                                    "components.views.trip.offers.transport_company.modal_details.city"
                                  )
                                }}:</b
                              >
                              {{ stop.city }}
                            </div>
                            <div class="text-caption">
                              <b
                                >{{
                                  $t(
                                    "components.views.trip.offers.transport_company.modal_details.location"
                                  )
                                }}:</b
                              >
                              {{ stop.location }}
                            </div>
                          </v-col>
                        </v-row>
                      </v-timeline-item>
                    </v-timeline>
                  </v-col>
                </v-row>
                <v-row v-if="loaderStops">
                  <v-col cols="12" class="pt-0">
                    <v-skeleton-loader
                      v-for="index in 4"
                      :key="index"
                      type="list-item-avatar-three-line"
                    />
                  </v-col>
                </v-row>
                <v-row v-if="stops.length == 0 && !loaderStops">
                  <v-col cols="12" align="center" justify="center">
                    <div id="no-result">
                      <v-icon>fa-solid fa-triangle-exclamation</v-icon>
                      <p>
                        <b>{{
                          $t(
                            "components.views.trip.offers.transport_company.modal_details.no_result"
                          )
                        }}</b>
                      </p>
                    </div>
                  </v-col>
                </v-row>
              </v-container>
              <v-divider />
              <div class="informacion-page">
                {{ info }}
              </div>
            </v-container>
          </v-card>
        </v-tab-item>
      </v-tabs-items>
    </v-card-text>
  </v-card>
</template>

<script>
import validate from "@/plugins/validate";
import { mapActions } from "vuex";

export default {
  props: {
    idMonitoringOrder: {
      type: Number,
      default: 0,
    },
    type: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      ...validate,
      tab: "Details",
      stops: [],
      searchStops: "",
      pageStops: 0,
      indexTable: 0,
      weight: "",
      doc_ref1:"",
      loaderStops: false,
      totalCount: 0,
      resultForPageStops: 20,
    };
  },
  computed: {
    headersLicensePlate() {
      return [
        {
          text: this.$t(
            "components.views.trip.offers.transport_company.modal_details.table_plate.license_plate"
          ),
          align: "center",
          class: "text-center",
          value: "licensePlate",
        },
      ];
    },
    info() {
      return this.totalCount > 0
        ? `${this.$t(
            "components.views.trip.offers.transport_company.modal_details.items_displayed"
          )}: ${this.totalCount}`
        : "";
    },
    urlStops() {
      return `/Travels/Stops/${this.idMonitoringOrder}/${this.resultForPageStops}/${this.pageStops}`;
    },
    urlWeight() {
      return `/Offers/DetailOfferInformation?idMonitoringOrder=${this.idMonitoringOrder}`;
    },
    urlStopsCount() {
      return `/Travels/CountStops/${this.idMonitoringOrder}`;
    },
  },
  watch: {
    searchStops(newValue) {
      if (!newValue) {
        this.cleanField();
      }
    },
  },
  mounted() {
    this.loadData();
  },
  methods: {
    ...mapActions("snackbar", ["showSnack"]),

    async loadData() {
      this.loaderStops = true;

      await this.axios
        .get(`${this.urlWeight}`)
        .then((response) => {
          this.weight = response.data.weight;
          this.doc_ref1 = response.data.doc_ref1;
        })
        .catch((error) => {});

      const searchVal = this.searchStops == "" ? "" : `?search=${this.searchStops}`;

      await this.axios
        .get(`${this.urlStops}${searchVal}`)
        .then((response) => {
          this.stops = response.data;
        })
        .catch((error) => {
          this.showSnack({
            text: error.response.data,
            title: "Error",
            name: "error",
          });
        })
        .finally(() => {
          this.loaderStops = false;
        });

      if (this.loaderStops) {
        await this.axios
          .get(`${this.urlStopsCount}${searchVal}`)
          .then((response) => {
            this.totalCount = response.data;
          })
          .catch((error) => {
            this.showSnack({
              text: error.response.data,
              title: "Error",
              name: "error",
            });
          })
          .finally(() => {
            this.loaderStops = false;
          });
      }
    },

    cleanField() {
      this.pageStops = 0;
      this.searchStops = "";
      this.loadData();
    },

    onSearch() {
      this.loadData();
    },
  },
};
</script>

<style lang="scss" scoped>
@import './ModalDetailsStyle.scss';
</style>
