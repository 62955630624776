
<template>
  <v-card id="card-modal-assign-driver" class=" elevation-4">
    <v-card-title class="justify-start py-2 card-modaltitle">
      <div class="pl-4">
        <h3 class="primary--text">
          {{ $t("components.views.trip.active.modal_details.detail") }}
        </h3>
      </div>
      <v-btn depressed icon color="primary" class="cont" @click.stop="$emit('closeForm')">
        <v-icon class="mr-1">fa-solid fa-xmark</v-icon>
      </v-btn>
    </v-card-title>

    <v-card-text>
      <v-container fluid class="pt-0 mt-0">
        <v-row no-gutters class="mb-0 pb-0">
          <v-spacer />
          <v-col md="12" class="mr-2 mb-0 pb-0">
            <v-text-field
              prepend-icon="fa-solid fa-magnifying-glass"
              outlined
              hide-details
              v-model="searchStops"
              clearable
              dense
              @keydown.enter="onSearch"
              @click:clear="cleanField()"
              :label="$t('components.views.trip.active.modal_details.search')"
              :placeholder="$t('components.views.trip.active.modal_details.search_placeholder')"
            />
          </v-col>
        </v-row>
        <v-container id="content-stop" class="mt-1">
          <v-row v-if="!loaderStops && stops.length > 0" id="row-result">
            <v-col cols="12" class="pt-0">
              <v-timeline align-top dense>
                <v-timeline-item color="primary" fill-dot v-for="(stop, i) in stops" :key="i">
                  <template slot="icon">
                    <v-icon color="white" v-if="i == 0">$vuetify.icons.custom.Envio </v-icon>
                    <v-icon color="white" v-if="i !== 0">$vuetify.icons.custom.Entrega </v-icon>
                  </template>
                  <v-row class="pt-1">
                    <v-col cols="3">
                      <label>
                        {{ $t("components.views.trip.active.modal_details.date") }}:
                        {{ moment.utc(stop.schedule_date, "DD/MM/YYYY H:mm").format("DD/MM/YYYY") }}
                      </label>
                      <p>
                        {{ $t("components.views.trip.active.modal_details.hour") }}:
                        {{ moment.utc(stop.schedule_date, "DD/MM/YYYY H:mm").format("H:mm:ss") }}
                      </p>
                    </v-col>
                    <v-col>
                      <strong>{{ stop.customer }}</strong>
                      <div class="text-caption">
                        <b>{{ $t("components.views.trip.active.modal_details.address") }}:</b>
                        {{ stop.address }}
                      </div>
                      <div class="text-caption">
                        <b>{{ $t("components.views.trip.active.modal_details.city") }}:</b>
                        {{ stop.city }}
                      </div>
                      <div class="text-caption">
                        <b>{{ $t("components.views.trip.active.modal_details.location") }}:</b>
                        {{ stop.location }}
                      </div>
                    </v-col>
                  </v-row>
                </v-timeline-item>
              </v-timeline>
            </v-col>
          </v-row>
          <v-row v-if="loaderStops">
            <v-col cols="12" class="pt-0">
              <v-skeleton-loader
                v-for="index in 4"
                :key="index"
                type="list-item-avatar-three-line"
              />
            </v-col>
          </v-row>
          <v-row v-if="stops.length == 0 && !loaderStops">
            <v-col cols="12" align="center" justify="center">
              <div id="no-result">
                <v-icon>fa-solid fa-triangle-exclamation</v-icon>
                <p>
                  <b>{{ $t("components.views.trip.active.modal_details.no_result") }}</b>
                </p>
              </div>
            </v-col>
          </v-row>
        </v-container>
        <v-divider />
        <div class="informacion-page">
          {{ info }}
        </div>
      </v-container>
    </v-card-text>
  </v-card>
</template>

<script>
import validate from "@/plugins/validate";
import { mapActions } from "vuex";

export default {
  props: {
    idMonitoringOrder: {
      type: Number,
      default: 0,
    },
    type: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      ...validate,
      tab: "DetailsStop",
      stops: [],
      searchStops: "",
      pageStops: 0,
      indexTable: 0,
      loaderStops: false,
      totalCount: 0,
      resultForPageStops: 20,
    };
  },
  computed: {
    headersLicensePlate() {
      return [
        {
          text: this.$t("components.views.trip.active.modal_details.table_plate.license_plate"),
          align: "center",
          class: "text-center",
          value: "licensePlate",
        },
      ];
    },
    headersBusinessTransport() {
      return [
        {
          text: this.$t("components.views.trip.active.modal_details.table_business.date_time"),
          align: "center",
          class: "text-center",
          value: "date",
        },
        {
          text: this.$t(
            "components.views.trip.active.modal_details.table_business.company_transporter"
          ),
          align: "center",
          class: "text-center",
          value: "transportCompany",
        },
        {
          text: this.$t("components.views.trip.active.modal_details.table_business.status"),
          align: "center",
          class: "text-center",
          value: "idStatus",
        },
      ];
    },
    info() {
      return this.totalCount > 0
        ? `${this.$t("components.views.trip.active.modal_details.items_displayed")}: ${
            this.totalCount
          }`
        : "";
    },
    urlLicensePlate() {
      return `/Offers/LicensePlate/${this.idMonitoringOrder}`;
    },
    urlLicensePlateCount() {
      return `/Offers/CountLicensePlate/${this.idMonitoringOrder}`;
    },
    urlRequestListET() {
      return `/Offers/RequestListTransportCompany/${this.idMonitoringOrder}`;
    },
    urlCountRequestListET() {
      return `/Offers/RequestListAllCount/${this.idMonitoringOrder}`;
    },
    urlStops() {
      return `/Travels/Stops/${this.idMonitoringOrder}/${this.resultForPageStops}/${this.pageStops}`;
    },
    urlStopsCount() {
      return `/Travels/CountStops/${this.idMonitoringOrder}`;
    },
    typeProp() {
      return this.type;
    },
  },
  watch: {
    searchStops(newValue) {
      if (!newValue) {
        this.cleanField();
      }
    },
  },
  mounted() {
    this.loadData();
  },
  methods: {
    ...mapActions("snackbar", ["showSnack"]),

    async loadData() {
      this.loaderStops = true;

      const searchVal = this.searchStops == "" ? "" : `?search=${this.searchStops}`;

      await this.axios
        .get(`${this.urlStops}${searchVal}`)
        .then((response) => {
          this.stops = response.data;
        })
        .catch((error) => {
          this.showSnack({
            text: error.response.data,
            title: "Error",
            name: "error",
          });
        })
        .finally(() => {
          this.loaderStops = false;
        });

      if (this.loaderStops) {
        await this.axios
          .get(`${this.urlStopsCount}${searchVal}`)
          .then((response) => {
            this.totalCount = response.data;
          })
          .catch((error) => {
            this.showSnack({
              text: error.response.data,
              title: "Error",
              name: "error",
            });
          })
          .finally(() => {
            this.loaderStops = false;
          });
      }
    },

    changeKey() {
      this.indexTable += 1;
    },

    cleanField() {
      this.pageStops = 0;
      this.searchStops = "";
      this.loadData();
    },

    onSearch() {
      this.loadData();
    },

    infiniteScrolling() {
      let searchStops = this.searchStops == "" ? "" : `?search=${this.searchStops}`;
      setTimeout(() => {
        this.pageStops++;
        this.axios
          .get(`${this.urlStops}${searchStops}`)
          .then((response) => {
            if (response.data.length) {
              response.data.forEach((item) => this.stops.push(item));
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }, 500);
    },
  },
};
</script>


<style scoped>
@import './ModalDetailsStyle.scss';
</style>
